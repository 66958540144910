<template>
    <div class="p-relative">
        <b-message type="is-warning" has-icon>
            <p>
                Hubo errores al publicar algunos artículos en la API de L2A!
                <br>
                Puedes visualizarlos al final de esta sección.
            </p>
        </b-message>     
        <div class="box overflow-x-auto">
            <h1 class="has-text-success title is-5">Cargados con éxito</h1>
            <b-table :data="logEntryItems" :loading="isLoadingLogEntryItems">
                <b-table-column field="data.id" label="ID" v-slot="props" centered searchable sortable>
                    <span class="tag">
                        {{ props.row.data.id }}
                    </span>
                </b-table-column>

                <b-table-column field="data.name" label="Nombre" v-slot="props" centered searchable sortable>
                    <small>
                        {{ props.row.data.name }}
                    </small>
                </b-table-column>

                <b-table-column field="data.description" label="Descripción" v-slot="props" centered searchable sortable>
                    <small>
                        {{ props.row.data.description }}
                    </small>
                </b-table-column>

                <b-table-column field="data.always_on" label="Always On" v-slot="props" centered sortable>
                    <span class="tag" :class="{'is-success': props.row.data.always_on, 'is-danger': !props.row.data.always_on}">
                        {{ props.row.data.always_on ? 'Si' : 'No' }}
                    </span>
                </b-table-column>

                <b-table-column field="data.created_at" label="Fecha de carga" v-slot="props" centered searchable sortable>
                    <span class="tag">
                        <b-icon icon="clock"/>&nbsp;{{ props.row.data.created_at.substr(0,19) }}
                    </span>
                </b-table-column>

                <b-table-column field="data.expires" label="Fecha de expiración" v-slot="props" centered searchable sortable>
                    <span class="tag">
                        <b-icon icon="stopwatch"/>&nbsp;{{ props.row.data.expires.substr(0,10) }}
                    </span>
                </b-table-column>

                <b-table-column field="data.total" label="Total" v-slot="props" centered searchable sortable>
                    <small>
                        {{ props.row.data.total }}
                    </small>
                </b-table-column>

                <b-table-column label="Modificado por" centered>
                    <span class="tag">
                        <b-icon icon="user"/>&nbsp;{{ modifiedBy }}
                    </span>
                </b-table-column>

                <b-table-column field="data.internal_code" label="SKU" v-slot="props" centered searchable sortable>
                    <span class="tag">
                        {{ props.row.data.internal_code }}
                    </span>
                </b-table-column>

                <template #empty>
                    <div class="has-text-centered">Nada para mostrar</div>
                </template>
            </b-table>
        </div>
        <br>
        <div class="box overflow-x-auto" v-show="hasL2AInfo">
            <h1 class="has-text-danger title is-5">Errores L2A</h1>
            <b-table :data="l2aEntryItems" :loading="isLoadingLogEntryItems">
                <b-table-column field="data.sapCode" label="Item SAP Code" v-slot="props" centered searchable sortable>
                    <span class="tag is-danger">
                        {{ props.row.sapCode }}
                    </span>
                </b-table-column>

                <b-table-column field="data.errors" label="Errores" v-slot="props" centered>
                    <b-message type="is-danger" size="is-small" has-icon>
                        {{ props.row.errors }}
                    </b-message>
                </b-table-column>

                <template #empty>
                    <div class="has-text-centered">Nada para mostrar</div>
                </template>
            </b-table>
        </div>
    </div>
</template>

<script>
export default {
    mounted(){
        this.getLogDetails()
    },
    data() {
        return {
            modifiedBy: null,
            logEntryItems: [],
            l2aEntryItems: [],
            isLoadingLogEntryItems: false,        
        }
    },
    computed: {
        hasL2AInfo(){
            return this.l2aEntryItems.length > 0
        }
    },
    methods: {
        getLogDetails(fileId=null){
            this.isLoadingLogEntryItems = true
            let logDetailsId = this.$route.params.id
            this.$store.dispatch('getUploadedTemplateLogDetails', fileId || logDetailsId)
            .then(res=>{
                let data = res.data
                if( !data.info )return;
                
                this.logEntryItems = data.info.filter((item)=>{
                    return item.data !== undefined
                })

                this.l2aEntryItems = data.info_l2a || []

                this.modifiedBy = data.userEmail
            })
            .catch(err=>{
                this.$buefy.toast.open({
                    type: 'is-danger',
                    message: 'Hubo un error: ' + err.toString(),
                    position: 'is-bottom-right'
                })
            })
            .finally(()=>{
                this.isLoadingLogEntryItems = false
            })
        },
    }
}
</script>
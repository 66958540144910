<template>
    <aside :class="{'open': isSideMenuOpen}" @click="closeSideMenu" class="has-background-primary">
        <router-link to="/" tag="head" class="is-cursor-pointer is-no-selectable" title="Ir al inicio">
            <figure class="image is-128x128 m-auto">
                <img src="@/assets/icons/joy.png" alt="logo">
            </figure>
            <b-icon title="Cerrar menu lateral" icon="arrow-left" size="is-medium" class="close is-cursor-pointer has-text-light"/>
        </router-link>
        <ul>
            <li>
                <router-link to="/">
                    <b-icon icon="clock" size="is-small"/>
                    <span>
                        Histórico
                    </span>
                </router-link>
            </li>
            <li>
                <router-link to="/generar-reporte">
                    <b-icon icon="file-arrow-down" size="is-small"/>
                    <span>
                        Generar reporte
                    </span>
                </router-link>
            </li>
            <li>
                <router-link to="/cargar-catalogo">
                    <b-icon icon="file-excel" size="is-small"/>
                    <span>
                        Cargar catálogo
                    </span>
                </router-link>
            </li>
            <li>
                <router-link to="/descargar-plantilla">
                    <b-icon icon="download" size="is-small"/>
                    <span>
                        Descargar plantilla
                    </span>
                </router-link>
            </li>
            <li>
                <router-link to="/reporte-sl">
                    <b-icon icon="file-csv" size="is-small"/>
                    <span>
                        Reporte SL
                    </span>
                </router-link>
            </li>
            <li>
                <a href="#" @click="$store.dispatch('logout')">
                    <b-icon icon="power-off" size="is-small"/>
                    <span>
                        Cerrar sesión
                    </span>
                </a>
            </li>
        </ul>
    </aside>
</template>

<script>
export default {
    computed: {
        isSideMenuOpen(){
            return this.$store.state.app.isSideMenuOpen
        }
    },
    mounted(){
        document.addEventListener('keyup', (ev)=>{
            if(ev.keyCode == 27)
                this.closeSideMenu()
        })
    },
    methods: {
        toggleCollapsible(ev){
            let target = ev.target
            if( target.tagName != 'A')
                target = target.closest('a')
                
            target.classList.toggle('active')

            let isActive = target.classList.contains('active')
            let content = target.nextElementSibling

            if( isActive )
                content.style.maxHeight = content.scrollHeight + "px";
            else
                content.style.maxHeight = null
        },
        closeSideMenu(){
            this.$store.commit('setSideMenu', false)
        }
    }
}
</script>

<style scoped>

</style>
<template>
    <section class="hero is-fullheight">
        <div class="hero-body">
            <div class="container">
                <div class="columns">
                    <div class="column is-3"></div>
                    <div class="column is-6 has-text-centered">
                        <p class="pb-6 has-text-warning title is-size-3">
                            ¡Bienvenido! 
                            <br> 
                            Administrador de catálogo Joy
                        </p>
                        <figure class="image is-16x9 m-auto block py-6 px-6">
                            <img src="@/assets/icons/login_splash.png">
                        </figure>
                        <div v-if="!$keycloak.authenticated">
                            <b-button @click="loginFn" type="is-warning" size="is-medium" class="block m-auto w-60">
                                Iniciar sesión
                            </b-button>
                        </div>
                        <div v-else>
                            <b-message type="is-danger" has-icon class="mx-6">
                                Hola <b>{{ $keycloak.tokenParsed.given_name | capitalize }}</b>,
                                lo lamentamos pero no tienes acceso. Puedes solicitarlo al área de BIS.
                                <b-button @click="retryLogin" type="is-danger" size="is-small" class="d-block ml-auto mt-2">Reintentar</b-button>
                            </b-message>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <footer-info/>
    </section>
</template>

<script>
import footerInfo from '@/components/global/FooterInfo'
export default {
    head: {
        title: {
            inner: 'Admin JOY | Ingreso'
        },
    },
    components: {
        footerInfo,
    },
    computed: {
        loginFn(){
            return this.$keycloak.loginFn ? this.$keycloak.loginFn : ()=>{alert('El servicio de autenticación no esta disponible!')}
        }
    },
    methods: {
        retryLogin(){
            location.reload(true)
        }
    }
}
</script>

<style scoped>
.hero{
    background-image: url('@/assets/login_bg.png');
    background-size: cover;
    background-position: center;
}
</style>


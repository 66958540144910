<template>
    <section class="hero is-fullheight">
        <div class="hero-body has-text-centered">
            <div class="container">
                <figure class="image is-128x128 mx-auto mb-6">
                    <img src="@/assets/icons/lost.png" alt="">
                </figure>
                <h1 class="title is-family-secondary">¿Te has perdido?</h1>
                <p class="subtitle mt-3">
                    No pasa nada, puedes regresar a donde estabas
                </p>
                <b-button tag="router-link" to="/" type="is-warning">
                    Volver
                </b-button>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    head: {
        title: {
            inner: 'Admin JOY | 404'
        },
    }
}
</script>

<template>
    <div class="box">
        <div v-if="hasSelectedFile" class="d-flex has-text-centered">
            <b-notification
                @close="dropFiles = []"
                :closable="!isUploadingFile"
                class="w-100 d-block m-auto"
                type="is-primary is-light">
                <figure class="m-auto image is-128x128">
                    <img :src="require(`@/assets/icons/${selectedFile.ext == 'csv' ? 'csv.png' : 'xls.png'}`)">
                </figure>
                <b class="mt-3 d-block is-6">{{ selectedFile.name }}</b>
                <small class="d-block is-7">{{ selectedFile.size }}kb</small>
                <b-button @click="sendFile" type="is-primary" class="my-3 d-block mx-auto" :loading="isUploadingFile">Subir</b-button>
                <b-progress
                    v-show="isUploadingFile"
                    class="p-absolute bottom-0" 
                    :value="uploadProgress" 
                    show-value 
                    format="percent" 
                    size="is-medium" 
                    type="is-success" 
                    :rounded="false"
                />
            </b-notification>
        </div>
        <b-field v-else>
            <b-upload v-model="dropFiles" accept=".xlsx" multiple expanded drag-drop>
                <section class="section">
                    <div class="content has-text-centered">
                        <p><b-icon icon="upload" size="is-large"/></p>
                        <p>Arrasta tu plantilla <b>excel</b> o da clic aqui</p>
                    </div>
                </section>
            </b-upload>
        </b-field>
    </div>
</template>

<script>
export default {
    computed: {
        hasSelectedFile(){
            return this.dropFiles.length > 0
        },
        selectedFile(){
            if( this.hasSelectedFile ){
                let f = this.selectedFileForSend

                return {
                    name: f.name,
                    size: f.size,
                    ext: f.name.substr( f.name.indexOf('.')+1) 
                }
            }
            return null
        },
        selectedFileForSend(){
            return this.hasSelectedFile ? this.dropFiles[0] : null
        },
        backendAPI(){
            return this.$store.state.backendAPI
        }
    },
    data() {
        return {
            dropFiles: [],
            uploadProgress: 0,
            isUploadingFile: false,
        }
    },
    methods: {
        deleteDropFile(index) {
            // Abort
            if( this.isUploadingFile )return;

            this.dropFiles.splice(index, 1)
        },
        sendFile(){
            if( !this.hasSelectedFile )return;

            let file = this.selectedFileForSend
            let fileName = window.getUniqueId()
            let fileExtension = 'xlsx'
            let originalFileName = this.selectedFile.name
            let userEmail = this.$keycloak.tokenParsed.email

            let API = this.backendAPI

            let xhr = new XMLHttpRequest();
            xhr.open(
                'PUT', 
                `${API.rootUrl}${API.services.uploadCatalogTemplate.url}${fileName}.${fileExtension}`, 
                true
            )

            // Custom headers
            xhr.setRequestHeader('Content-Type', 'application/octet-stream');
            xhr.setRequestHeader("x-api-key", API.apiKey);
            xhr.setRequestHeader("x-user-email", userEmail);
            xhr.setRequestHeader("x-file-name", originalFileName);

            // Handlers
            xhr.onloadstart = ()=>{
                this.uploadProgress = 0
                this.isUploadingFile = true
            }

            xhr.onload = ()=>{
                this.isUploadingFile = false
            }

            xhr.upload.onprogress = (e)=>{
                if (e.lengthComputable)
                    this.uploadProgress = Math.ceil(((e.loaded) / e.total) * 100);
            }

            xhr.onreadystatechange = ()=>{
                if (xhr.readyState === 4) {
                    let response = window.parseJwt(xhr.responseText)

                    console.log( xhr, response )

                    if (xhr.status === 200 || xhr.status === 201) {
                        // Success

                        this.$buefy.dialog.confirm({
                            title: '¡Listo!',
                            message: 'Tu archivo fue cargado correctamente',
                            confirmText: 'Ir al historial',
                            cancelText: 'Aceptar',
                            type: 'is-success',
                            hasIcon: true,
                            onConfirm: () => this.$router.push('/'),
                            onCancel: ()=> this.resetAll()
                        })

                        this.resetAll()

                    } else {
                        // Failed
                        this.$buefy.dialog.confirm({
                            title: `Algo falló con estatus ${xhr.status}`,
                            message: (response && response.errors ? response.errors + '. ' : '') + 'Por favor, asegurate de llenar correctamente la plantilla. ¿Quieres re-intentarlo?',
                            confirmText: 'Si, otra vez',
                            type: 'is-danger',
                            hasIcon: true,
                            onConfirm: () => this.sendFile(),
                            onCancel: ()=> this.resetAll()
                        })
                    }
                }
            }

            xhr.send(file)
        },
        resetAll(){
            this.dropFiles = []
            this.uploadProgress = 0
            this.isUploadingFile = false
        }
    }
}
</script>
